import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { IQuestions } from '../../../types/strapiDocs';
import { IUser } from '../../../types/userInterface';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import Swal from 'sweetalert2';
import LoaderComponent from '../../Loader/loader.component';
import { updateUser } from '../../../api/usersApi';

interface Props {
  questions: IQuestions[];
}

interface Answer {
  id: number;
  question: string;
  answer: string;
  detail?: string | undefined;
}

const SaludEmocionesComponent = ({ questions }: Props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        // Si el campo es el textarea y está oculto, lo ignoramos
        if (campo.type === 'textarea' && campo.hidden) {
          return;
        }
        todosCompletos = todosCompletos && campo.value !== '';
      }
    });

    gruposRadio.forEach((nombre) => {
      const seleccionado =
        document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;
    });

    setFormularioValido(todosCompletos);
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = {
        ...user,
      };
      const userId = currentUser._id || '';

      currentUser.step = 8;
      const saludYEmociones = currentUser.saludYEmociones || {};
      saludYEmociones.questions = answers;
      currentUser.saludYEmociones = saludYEmociones;

      await updateUser(userId, currentUser, token);
      navigate('/sexualidad-y-emociones');
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const isChecked = (questionId: number, value: string) => {
    const isCheck =
      answers.some((a) => a.id === questionId && a.answer === value) || false;
    return isCheck;
  };

  const renderQuestion = (
    question: IQuestions,
    option: { name: number; value: string; label: string },
    index: number
  ) => {
    return (
      <div className="form-check" key={index}>
        <input
          className="form-check-input requiredField"
          type="radio"
          name={`${option.name}`}
          id={`${option.value}-${question.id}`}
          value={option.value}
          checked={isChecked(question.id, option.value)}
          onChange={(e) => {
            const currentAnswers = [...answers];
            const index = currentAnswers.findIndex((a) => a.id === question.id);
            if (index > -1) {
              currentAnswers[index].answer = option.value;
            } else {
              currentAnswers.push({
                id: question.id,
                question: question.Nombre,
                answer: e.target.value,
                detail: '',
              });
            }
            setAnswers(currentAnswers);
          }}
        />
        <label
          className="form-check-label"
          htmlFor={`${option.value}-${question.id}`}
        >
          {option.label}
        </label>
      </div>
    );
  };

  const renderQuestionDetail = (answer: Answer, question: IQuestions) => {
    const currentValue = answers.find((a) => a.id === answer.id)?.detail || '';
    return (
      <div className="form-row">
        <label className="form-label">Especifica cuál:</label>
        <textarea
          className="form-control requiredField"
          placeholder="Escribe aquí el diagnóstico y notas adicionales"
          id={`detail-${answer.id}`}
          value={currentValue}
          onChange={(e) => {
            e.preventDefault();
            const currentAnswers = [...answers];
            const index = currentAnswers.findIndex((a) => a.id === answer.id);
            if (index > -1) {
              currentAnswers[index].detail = e.target.value;
            } else {
              currentAnswers.push({
                id: answer.id,
                question: question.Nombre,
                answer: '',
                detail: e.target.value,
              });
            }
            setAnswers(currentAnswers);
          }}
        ></textarea>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.amigasUser || {});
        const saludYEmociones = currentUser?.amigasUser?.saludYEmociones || {};
        const preguntas = saludYEmociones.questions || [];

        setAnswers(preguntas);
        verifyFields();
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    verifyFields();
  }, [answers]);

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          {questions.map((question, index) => (
            <div className="form-row" key={index}>
              <div className="form-row">
                <label className="form-label label--icon">
                  {question.Nombre}{' '}
                  {question.tooltip && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>{question.tooltip}</Tooltip>}
                    >
                      <span className="material-icons-outlined icon">
                        help_outline
                      </span>
                    </OverlayTrigger>
                  )}
                </label>
                <div className="form-check-multi">
                  {[
                    {
                      value: 'si',
                      label: 'Sí',
                      name: question.id,
                    },
                    {
                      value: 'no',
                      label: 'No',
                      name: question.id,
                    },
                    {
                      value: 'no-estoy-segura',
                      label: 'No estoy segura',
                      name: question.id,
                    },
                  ].map((option, index) =>
                    renderQuestion(question, option, index)
                  )}
                </div>
              </div>
              {question.showMore &&
                answers.some((a) => {
                  return a.id === question.id && a.answer === 'si';
                }) &&
                renderQuestionDetail(
                  {
                    id: question.id,
                    question: question.Nombre,
                    answer: 'si',
                    detail: '',
                  },
                  question
                )}
            </div>
          ))}
          <div className="form-row module-top40">
            <p className="text-center">
              ¡Y recuerda! Si lo necesitas podemos acordar una llamada
            </p>
            {/* <a href="tel:569982729131" className="btn btn--type2 btn--icon">
              <i className="icon icon--phone-call"></i>
              Información de aborto legal en Chile 569 982 729 131
            </a> */}
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SaludEmocionesComponent;
